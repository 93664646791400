@tailwind base;
@tailwind components;
@tailwind utilities;


/* markdown class is for the services' pages */

.markdown h1{
    font-size:2em;
}
.markdown h2{
    font-size:1.5rem;
}
.markdown h3{
    font-size:1.17em
}
.markdown h4{
    font-size:1em;
}
.markdown h5{
    font-size:0.83em;
}
.markdown h6{
    font-size:0.67em;
}


.markdown ul {
    list-style-type: disc; 
    margin-left: 20px; 
    margin-bottom: 50px;
    font-size: 0.8em;
  }
  
.markdown ol {
  list-style-type: decimal;
  margin-left: 20px;
  margin-top: 30px;
  font-size: 1.5em;
}
  

.markdown li {
  margin-bottom: 10px; 
}


/* markdown-2 class is for the footer's pages */

.markdown-2 h4 {
  font-size: 2rem;
  font-weight: bold;
}

.markdown-2 h5 {
  font-size: 1.7rem;
  font-weight: bold;
}

.markdown-2 p {
  font-size: 1.3rem;
  margin-bottom: 2rem;
}

.markdown-2 ul {
  list-style-type: disc; 
  margin-left: 20px; 
  margin-bottom: 50px;
}


.markdown-2 li {
  margin-bottom: 10px;
  font-size: 1.3rem; 
}

  .custom-carousel-arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 40px;
    height: 40px;
    background-color: #333;
    color: white;
    font-size: 24px;
    border: none;
    cursor: pointer;
  }
  
  .custom-carousel-arrow-left {
    left: 20px;
    border-radius: 50% 0 0 50%;
  }
  
  .custom-carousel-arrow-right {
    right: 20px;
    border-radius: 0 50% 50% 0;
  }